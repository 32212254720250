import React from 'react';

import Button from '../Button';
import Spacing from '../Spacing';

export default function Breadcrumb({ sectionTitle, btnText,btnUrl, }) {

  return (
    <div className="container">
    
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-6">
        <ol className="breadcrumb text-capitalize">
        <li className="breadcrumb-item">
        <h4> Our OPD plans for corporates are simply stunning</h4>
        </li>
     
      </ol>
      <div className="cs_height_18" />
      <h1 className="cs_fs_72 mb-0">{sectionTitle}</h1>
          
        </div>
        <div className="col-xl-6 ">
        <ol className="breadcrumb text-align-center justify-content-center">
        <li className="breadcrumb-item ">
    {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
           </li>
     
     </ol>
        </div>
       </div>
       <style jsx>{`
     .breadcrumb-item
     { 
       margin-top: 30px;

  @media (min-width: 576px) {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
  }

  @media (min-width: 1200px) {
    margin-top: 60px;
  }

      `}</style>
      </div>
    
    
  );
}
