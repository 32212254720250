import React, { useState } from 'react';
import axios from 'axios';

export default function ContactForm() {
  const [formData, setFormData] = useState({
    from_name: '',
    mobile: '',
    from_email: '',
    subject: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/contact-form', formData, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });

      if (response.status === 200) {
        setResponseMessage('Submission successful!');
      } else {
        setResponseMessage('Submission failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred.');
    }
  };

  return (
    <form onSubmit={sendEmail}>
      <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
        <div className="row">
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Name</label>
            <input
              name='from_name'
              type="text"
              className="cs_form_field"
              placeholder="Enter your name"
              value={formData.from_name}
              onChange={handleInputChange}
            />
            <div className="mb-3" />
          </div>

          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Mobile Number</label>
            <input
              name='mobile'
              type="text"
              className="cs_form_field"
              placeholder="Enter mobile number"
              value={formData.mobile}
              onChange={handleInputChange}
            />
            <div className="mb-3" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              name='from_email'
              type="email"
              className="cs_form_field"
              placeholder="example@gmail.com"
              value={formData.from_email}
              onChange={handleInputChange}
            />
            <div className="mb-3" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Subject</label>
            <input
              name='subject'
              type="text"
              className="cs_form_field"
              placeholder="Write Subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
            <div className="mb-3" />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Message</label>
            <textarea
              name='message'
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Write something..."
              value={formData.message}
              onChange={handleInputChange}
            />
            <div className="mb-3" />
          </div>
          <div className="col-lg-12">
            <div className="cs_height_18" />
            <button type="submit" className="cs_btn cs_style_1">
              <span>Submit</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </div>
        </div>
      </div>
      {responseMessage && <p>{responseMessage}</p>}
    </form>
  );
}
