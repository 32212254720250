import React from 'react';
import logo from '../../assets/img/logo13.svg';
import Section from '../Section';

const Mail1 = () => {
  return (
    <Section topMd={200} topLg={150} topXl={110}>
      <div className='container'>
        <div className='col-md-12'>
          <div className='py-4 rest-of-page'>
            <div className='container text-center'>
              <img src={logo} alt='Logo' className='imageStyle' />
            </div>
            </div>
            <div className='content'>
              <h4 className='mt-5'>Dear [Name],</h4>
              <p>This email provides your login credentials for accessing the HealthMatrix Customer Relationship Management (CRM) portal.</p>
              <h4>Your Login Details:</h4>
              <ul>
                <li>Username: abc@mail.com</li>
                <li>Password: vnKxfbdbcjh</li>
              </ul>
              <h4>Important Security Note:</h4>
              <p>For enhanced security, this is a temporary password. Upon your first login, you'll be prompted to create a new, secure password of your choice.</p>
              <h4>Accessing the CRM Portal:</h4>
              <p>Click the following link to access the CRM portal: [CRM Portal Link]</p>
              <h4>What You Can Do in the CRM Portal:</h4>
              <ul>
                <li>View your subscription details and benefits</li>
                <li>Track your appointments and cashback history</li>
                <li>Manage your account information</li>
              </ul>
              <h4>We highly recommend changing your password immediately upon your first login.</h4>
              <p>If you have any trouble accessing the CRM portal or require assistance with your login credentials, please contact us at [Phone Number] or reply to this email.</p>
              <p>Welcome to the HealthMatrix member community!</p>
              <p className='mt-5'>Sincerely, <br />
                The HealthMatrix Team</p>
            </div>
            <style>
              {`
                .rest-of-page {
                  background: var(--hmisfront-easeclaim-com-linear-mariner-san-juan, linear-gradient(107deg, #8AC78B 0%, #19ADB4 100%));
                  padding: 20px;
                  margin-top: 75px;
                }
                .imageStyle {
                  width: 180px;
                  height: 50px;
                  flex-shrink: 0;
                }
                .content {
                  padding: 0 20px;
                  text-align: center;
                }
                .content h4, .content p, .content ul {
                  text-align: left;
                }
                .content ul {
                  list-style-position: inside;
                }
              `}
            </style>
          </div>
        </div>
      
    </Section>
  );
};

export default Mail1;
