import React from 'react';
import Spacing from '../../Spacing';
import Button from '../../Button';

export default function Banner({ bgUrl, imgUrl, title, heading, subTitle, btnText, btnUrl }) {
  return (
    <div className="container">
      <div
        className="cs_banner cs_style_1 cs_bg_filed"
        style={{ backgroundImage: `url(${bgUrl})` }}
      >
        <div className="cs_banner_content">
          <h3 className="cs_banner_title">{title}</h3>
          <h3 className="cs_banner_heading">{heading}</h3>
          <p className="cs_banner_subtitle">
            {subTitle}
          </p>
          {btnText && (
            <>
              <Spacing md="25" lg="25" xl="25" />
              <Button btnUrl={btnUrl} btnText={btnText} />
            </>
          )}
        </div>
        <img src={imgUrl} alt="Banner" className="cs_banner_img" />
      </div>
      <style jsx>{`
   .cs_banner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center;
    padding: 250px 20px;
  }
  
  .cs_banner_content {
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  .cs_banner_title {
    font-size: 45px;
    margin-bottom: 10px;
  }
  
  .cs_banner_heading {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .cs_banner_subtitle {
    font-size: 16px;
  }
  
  .cs_banner_img {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
  
  @media (min-width: 768px) {
    .cs_banner {
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      padding: 100px 50px;
    }
  
    .cs_banner_content {
      max-width: 50%;
      margin-bottom: 0;
    }
  
    .cs_banner_title {
      font-size: 32px;
    }
  
    .cs_banner_heading {
      font-size: 24px;
    }
  
    .cs_banner_subtitle {
      font-size: 18px;
    }
  
    .cs_banner_img {
      max-width: 40%;
    }
  }
  
  @media (min-width: 1200px) {
    .cs_banner_content {
      max-width: 500px;
    }
  
    .cs_banner_title {
      font-size: 45px;
    }
  
    .cs_banner_heading {
      font-size: 28px;
    }
  
    .cs_banner_subtitle {
      font-size: 20px;
    }
  
    .cs_banner_img {
      max-width: 500px;
    }
  }
      `}</style>
    </div>
  );
}
