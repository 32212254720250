import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    c_name: '',
    c_phone: '',
    c_email: '',
    number_emp: '',
    com_name: '',
    c_message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/get-quote', formData, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      
      if (response.status === 200) {
        setResponseMessage('Submission successful!');
        localStorage.setItem('quoteResponse', JSON.stringify(response.data));
      } else {
        setResponseMessage('Submission failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred.');
    }
  };

  return (
    <>
      <form action="#" className="row" onSubmit={handleSubmit}>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Name</label>
          <input
            type="text"
            name="c_name"
            className="cs_form_field"
            placeholder="Enter your name"
            value={formData.c_name}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Phone Number</label>
          <input
            type="text"
            name="c_phone"
            className="cs_form_field"
            placeholder="Enter your number"
            value={formData.c_phone}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">Email id</label>
          <input
            type="text"
            name="c_email"
            className="cs_form_field"
            placeholder="example@mail.com"
            value={formData.c_email}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-6">
          <label className="cs_input_label cs_heading_color">No.of employee</label>
          <input
            type="text"
            name="number_emp"
            className="cs_form_field"
            placeholder="No of people benefitted from this plan"
            value={formData.number_emp}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Company Name</label>
          <input
            type="text"
            name="com_name"
            className="cs_form_field"
            placeholder="Enter company name"
            value={formData.com_name}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-12">
          <label className="cs_input_label cs_heading_color">Message</label>
          <textarea
            name="c_message"
            cols={30}
            rows={5}
            className="cs_form_field"
            placeholder="Type Here..."
            value={formData.c_message}
            onChange={handleInputChange}
          />
          <div className="mb-3" />
        </div>
        <div className="col-lg-12">
          <button type="submit" className="cs_btn cs_style_1">
            <span>Submit</span>
            <i>
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
              <img src="/images/icons/arrow_white.svg" alt="Icon" />
            </i>
          </button>
        </div>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </>
  );
}
