import React from 'react';

import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import AppointmentForm from '../../AppointmentForm';


export default function BannerSectionStyle11({
  bgUrl,
  imgUrl,
  sectionTitle,
  sectionTitleUp,
}) {
  return (
    <section
      className="cs_banner cs_style_5 cs_bg_filed"
      style={{ backgroundImage: `url(${bgUrl})` }}
    >
      <div className="cs_get-own_img">
        <img src={imgUrl} alt="Banner" />
      </div>
      <div className="container">
        <div className=" cs_gap_y_40">
          <div className="col-lg-6">
            <div className="cs_blur_bg">
              <SectionHeading title={sectionTitle} titleUp={sectionTitleUp} />
              <Spacing md="72" lg="50" />
              <AppointmentForm />
            </div>
          </div>
        </div>
        <style jsx>{`
           .cs_banner
     { 
       margin-top: 20px;

  @media (min-width: 576px) {
    margin-top: 30px;
  }

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  @media (min-width: 992px) {
    margin-top: 50px;
  }

  @media (min-width: 1200px) {
    margin-top: 60px;
  }
      .cs_blur_bg {
        position: absolute;
        top: 5%;
        transform: translateY(-10%);
        width: 50%;
        backdrop-filter: blur(10px);
        border-radius: 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.7);
        padding: 20px;
      }

    

      @media (max-width: 1199px) {
        .cs_blur_bg {
          width: 70%;
        }
      }

      @media (max-width: 991px) {
        .cs_blur_bg {
          width: 75%;
          top: 10%;
          transform: translateY(-10%);
          padding: 15px;
        }
      }
      @media (max-width: 768px) {
        .cs_blur_bg {
          width: 65%;
          top: 10%;
      
          transform: translateY(-10%);
          padding: 15px;
        }
      }

      @media (max-width: 576px) {
        .cs_blur_bg {
          width: 94%;
          top: 10%;
      

          transform: translateY(-10%);
          padding: 10px;
        }

        .cs_blur_bg .cs_input_label {
          font-size: 14px;
        }

        .cs_blur_bg .cs_form_field {
          font-size: 14px;
          padding: 10px;
        }

        .cs_blur_bg .cs_btn {
          font-size: 14px;
        }
      }
      `}</style>
      </div>
    </section>
  );
}
