import React from 'react';
import MenuWidget from '../Widget/MenuWidget';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import TextWidget from '../Widget/TextWidget';
import SocialWidget from '../Widget/SocialWidget';
import NewsletterStyle2 from '../Widget/NewsletterStyle2';
import { Link } from 'react-router-dom';
const menuDataOne = [
  // { title: 'OPD',  },
  { title: 'Essential Care Plan', href: '/' },
  { title: 'Wellness Plus Plan', href: '/' },
  { title: 'Health Harmony Plan', href: '/' },
  { title: 'Elite Health Plan', href: '/' },
];

const menuDataTwo = [
  { title: 'OPD for Coorporate', href: '/opd2' },
  { title: 'Claim Assistance', href: 'https://easeclaim.com/' },
  // { title: 'Testimonials', href: '/' },
  { title: 'About Us', href: '/about' },
  { title: 'Contact Us', href: '/contact' },
  { title: 'FAQs', href: '/Faq' },
];
const menuDataThree = [
  { title: 'Privacy Policy', href: '/policy' },
  { title: 'Terms and Conditions', href: '/terms' },
  { title: 'Refund Policy', href: '/refund' },
];

export default function FooterStyle2() {
  return (
    
    <footer className="cs_footer cs_style_2 cs_accent_bg cs_white_color">
          
      <NewsletterStyle2
        title="Need Help & Support?"
        subTitle="Did not find what you were looking? <br /> Let us help you."
      />
      <div className="container">
        <div className="cs_footer_in">
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <TextWidget
                logoUrl="/images/footer_logo_white.svg "
                text="A Unit Of Metagates Innovation"
              />
              <ContactInfoWidget />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
           
              <MenuWidget data={menuDataOne} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataTwo} />
            </div>
          </div>
          <div className="cs_footer_col">
            <div className="cs_footer_item">
              <MenuWidget data={menuDataThree} />
              <SocialWidget />
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom">
        <div className="container">
          <div className="cs_copyright">
         Copyright © 2024  <Link to="https://metagatesinnovation.com/">  Metagates Innovation.</Link> All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}
