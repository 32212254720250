import React, { useState } from 'react';
import axios from 'axios';

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const [toMail, setToMail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create the form data object
    const formData = new FormData();
    formData.append('to_mail', toMail);

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/subscribe-mail', formData);
    console.log(response);
      if (response.status === 200) {
        setResponseMessage('Subscription successful!');
      } else {
        setResponseMessage('Subscription failed.');
      }
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred due to localhost');
    }
  };
  return (
    <>
      {label && <p>Your Email</p>}
      <form className="cs_newsletter_form" onSubmit={handleSubmit}>
        <input
          type="email"
          value={toMail}
          onChange={(e) => setToMail(e.target.value)}
          className="cs_form_field"
          placeholder="example@email.com"
        />
        <button type='submit' className="cs_btn cs_style_1" >
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </>
  );
}
