import React from 'react';
import logo from '../../assets/img/logo13.svg';
import image from '../../assets/img/image 2.svg';
import Section from '../Section';

const Mail2 = () => {
  return (
    <Section topMd={200} topLg={150} topXl={110}>
      <div className='container'>
        <div className='col-md-12'>
          <div className='py-4 rest-of-page'>
            <div className='container text-center'>
              <img src={logo} alt='Logo' className='imageStyle' />
            </div>
            </div>
            <div className='text-center mt-5'><h2>Payment received. <br /> Thanks!</h2>
            <img src={image} alt='Logo' className='imageStyle2' />
            </div>
            <div className='content'>
              <h4 className='mt-5'>Dear [Name],</h4>
              <p>
               This email confirms your subscription to the [Plan Name] plan on HealthMatrix.co.in as we have successfully received your payment for invoice [invoice no.]</p>
              <h4>Invoice Details:</h4>
              <ul>
                <li>Subscription Plan: [Plan Name]</li>
                <li>Subscription Fee: [Amount]</li>
                <li>Invoice Date: [Date]</li>
              </ul>
              <p>A copy of your detailed invoice is attached to this email for your reference.</p>
              <h4>Next Steps:</h4>
              <ul>
              <li>Access the secure CRM portal using the login credentials you'll receive in a separate email.</li>
              </ul>
              <p>We appreciate your business and look forward to helping you achieve your healthcare goals!</p>
        
              <p className='mt-5'>Sincerely, <br />
                The HealthMatrix Team</p>
            </div>
            <style>
              {`
                .rest-of-page {
                  background: var(--hmisfront-easeclaim-com-linear-mariner-san-juan, linear-gradient(107deg, #8AC78B 0%, #19ADB4 100%));
                  padding: 20px;
                  margin-top: 75px;
                }
                .imageStyle {
                  width: 180px;
                  height: 50px;
                  flex-shrink: 0;
                }
                .content {
                  padding: 0 20px;
                  text-align: center;
                }
                .content h4, .content p, .content ul {
                  text-align: left;
                }
                .content ul {
                  list-style-position: inside;
                }
                .imageStyle2 {
                    width: 250px;
                    height: 250px;
                    flex-shrink: 0;
                  }
              `}
            </style>
          </div>
        </div>
      
    </Section>
  );
};

export default Mail2;
