import React from 'react';
import IconBoxStyle11 from '../../IconBox/IconBoxStyle11';
import Spacing from '../../Spacing';

export default function ContactInfoSection({ sectionTitle }) {
  return (
    <div className="container">
      <h2 className="cs_fs_72 mb-0">{sectionTitle}</h2>
      <Spacing md="70" lg="50" />
      <div className="row g-4 g-xl-3 g-xxl-5">
        <div className="col-xl-4">
          <a href="tel:+911135808929">
            <IconBoxStyle11
              title="Phone"
              subTitle="+918506884052"
              iconSrc="/images/contact/icon_1.svg"
            />
          </a>
        </div>
        <div className="col-xl-4">
          <IconBoxStyle11
            title="Email"
            subTitle="info@healthmatrix.co.in"
            iconSrc="/images/contact/icon_2.svg"
          />
        </div>
        <div className="col-xl-4">
          <a href="https://www.google.com/maps?q=E -2/189, 3rd Floor, Shastri Nagar, Delhi 110052" target="_blank" rel="noopener noreferrer">
            <IconBoxStyle11
              title="Location"
              subTitle=" E -2/189, 3rd Floor, Shastri Nagar, Delhi 110052"
              iconSrc="/images/contact/icon_3.svg"
            />
          </a>
        </div>
      </div>
      <Spacing md="35" />
      {/* Start Google Map */}
      <div className="cs_map">
        <iframe
          id="map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d875.1585537426272!2d77.18087926956221!3d28.670669175152454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQwJzE0LjQiTiA3N8KwMTAnNTMuNSJF!5e0!3m2!1sen!2sin!4v1720596684259!5m2!1sen!2sin"
          allowFullScreen
        />
        {/* <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d875.1585537426272!2d77.18087926956221!3d28.670669175152454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDQwJzE0LjQiTiA3N8KwMTAnNTMuNSJF!5e0!3m2!1sen!2sin!4v1720596684259!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      </div>
      {/* End Google Map */}
    </div>
  );
}
