import React from 'react';
import BannerSectionStyle3 from '../Section/BannerSection/BannerSectionStyle3';
import BannerSectionStyle4 from '../Section/BannerSection/BannerSectionStyle4';
import Section from '../Section';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2';
// import FunFactSection from '../Section/FunFactSection';
// import TeamSection from '../Section/TeamSection';
// import GallerySection from '../Section/GallerySection';
// import AwardSectionStyle2 from '../Section/AwardSection/AwardSectionStyle2';
import { pageTitle } from '../../helpers/PageTitle';
import FaqSectionStyle4 from '../Section/FaqSection/FaqSectionStyle4';
const departmentData = [
  {
    title: 'Empower individuals',
    subTitle:
      'We are with a vision to empower individuals with convenient and accessible healthcare services.',
    iconUrl: '/images/icons/calendar_white.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Founded by passionate experts',
    subTitle:
      'Our Team at Metagates Innovation Pvt Ltd is committed to leveraging technology to improve healthcare outcomes for all.',
    iconUrl: '/images/icons/calendar_white1.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Range of services',
    subTitle:
      'From convenient access to outpatient services to  medicine delivery, accurate diagnosis, and seamless telecommunications, we cover all.',
    iconUrl: '/images/icons/calendar_white2.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Putting users first',
    subTitle:
      'We prioritize privacy, security, and convenience, ensuring that every interaction with our platform is seamless and empowering.',
    iconUrl: '/images/icons/calendar_white3.svg',
    iconUrl1: '/images/icons/dedication.svg',
    href: '/departments/department-details',
  },
  {
    title: 'Dedication to excellence',
    subTitle:
      'HealthMatrix is constantly evolving to meet the ever-changing needs of the healthcare landscape. ',
    iconUrl: '/images/icons/calendar_white4.svg',
    href: '/departments/department-details',
  },
];
const faqData = [
  {
    title: 'What is healthmatrix.co.in?',
    content:
      ' healthmatrix.co.in is a comprehensive healthcare platform offering subscription-based services for OPD, telemedicine consultations, doctor consultancy, diagnosis bookings, and hospital recommendations.',
      
  },
  {
    title: 'How does the subscription service work?',
    content:
      'Our subscription service allows users to access a range of healthcare services for a fixed monthly fee. Users can subscribe to the service and enjoy benefits such as unlimited OPD consultations, telemedicine appointments, and more.',
  },
  {
    title: 'What are the benefits of subscribing?',
    content:
      'Subscribers enjoy convenient access to healthcare services from the comfort of their homes, cost savings compared to traditional healthcare visits, priority scheduling for appointments, and personalized healthcare recommendations.',
  },

  {
    title: 'Can I choose my preferred doctor for an OPD consultation?',
    content:
      ' Yes, you can browse through our list of experienced doctors, view their profiles, and select the one that best suits your needs.',
  },
  {
    title: 'What is telemedicine, and how does it work?',
    content:
      ' Telemedicine allows patients to consult with healthcare professionals remotely via video or phone calls. Users can schedule telemedicine appointments through our platform and connect with doctors from anywhere.',
  }, 
  {
    title: 'Is telemedicine secure and confidential?',
    content:
      '  Absolutely. We prioritize the security and confidentiality of patient information. Our telemedicine platform complies with all relevant data protection regulations to ensure privacy and security.',
  },

];
const featureListData = [
  {
    title: 'Convenient Access',
    subTitle:
      'Streamlined healthcare services at your fingertips, anytime, anywhere.',
    iconUrl: 'images/icons/professional.svg',
  },
  {
    title: 'Personalized Care',
    subTitle:
      'Tailored solutions to meet your unique needs, backed by expert guidance.',
    iconUrl: 'images/icons/comprehensive.svg',
  },
  {
    title: 'Secure & Empowering',
    subTitle:
      'Prioritizing privacy, we empower you to take control of your well-being.',
    iconUrl: 'images/icons/patient.svg',
  },
  {
    title: 'Innovation-Driven',
    subTitle:
      'At the forefront of digital healthcare transformation, shaping a healthier future.',
    iconUrl: 'images/icons/facilities.svg',
  },
];

// const funFactData = [
//   { number: '20+', title: 'Years of experience' },
//   { number: '95%', title: 'Patient satisfaction rating' },
//   { number: '5000+', title: 'Patients served annually' },
//   { number: '10+', title: 'Healthcare providers on staff' },
//   { number: '22+', title: 'Convenient locations in the area' },
// ];

// const teamData = [
//   {
//     imgUrl: 'images/about/doctor_1.png',
//     name: 'Dr. James Lee, MD',
//     designation: 'Head of Cardiologist',
//     description:
//       'With expertise in managing complex heart conditions and performing advanced cardiac procedures',
//     social: [
//       { icon: 'fa6-brands:facebook-f', href: '/about' },
//       { icon: 'fa6-brands:linkedin-in', href: '/about' },
//       { icon: 'fa6-brands:twitter', href: '/about' },
//     ],
//   },
//   {
//     imgUrl: 'images/about/doctor_2.png',
//     name: 'Dr. John Smith, MD',
//     designation: 'Emergency Medicine Physician',
//     description:
//       'With expertise in treating acute illnesses and injuries in medicine physician',
//     social: [
//       { icon: 'fa6-brands:facebook-f', href: '/about' },
//       { icon: 'fa6-brands:linkedin-in', href: '/about' },
//       { icon: 'fa6-brands:twitter', href: '/about' },
//     ],
//   },
//   {
//     imgUrl: 'images/about/doctor_3.png',
//     name: 'Dr. Susan Bones, MD',
//     designation: 'Board-certified Pediatrician',
//     description:
//       'With experience in managing complex medical conditions in children',
//     social: [
//       { icon: 'fa6-brands:facebook-f', href: '/about' },
//       { icon: 'fa6-brands:linkedin-in', href: '/about' },
//       { icon: 'fa6-brands:twitter', href: '/about' },
//     ],
//   },
// ];

// const galleryData = [
//   { imgUrl: '/images/about/portfolio_2_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_3_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_1_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_4_lg.jpeg' },
//   { imgUrl: '/images/about/portfolio_5_lg.jpeg' },
// ];
// const awardData = [
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Malcolm Baldrige National Quality Award',
//   },
//   { iconUrl: '/images/icons/award.svg', title: 'HIMSS Davies Award' },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Healthgrades National’s Best Hospital',
//   },
//   {
//     iconUrl: '/images/icons/award.svg',
//     title: 'Joint Commission Gold Seal of Approval',
//   },
// ];

export default function About() {
  pageTitle('About | ');
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to HealthMatrix,<br /> A healthcare solution"
        subTitle="Revolutionizing the way individuals access and manage their healthcare needs."
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="Our Journey"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/why_choose_us.jpeg"
          data={featureListData}
        />
      </Section>
      <Section
        topMd={185}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
        className="cs_gray_bg_1"
      >
        <FaqSectionStyle4
          sectionTitle="Frequently Asked <br />Questions"
          data={faqData}
          faqImgUrl="/images/home_4/faq_img.png"
          spiningImgUrl="/images/home_1/about_mini.svg"
          btnText="View more"
          btnUrl="/faq"
        />
      </Section>
      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Experts Doctor"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section> */}
      {/* <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/banner_bg_2.jpeg"
          title="Don’t Let Your Health <br />Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced <br />medical professionals today!"
          center
        />
      </Section> */}
    </>
  );
}
