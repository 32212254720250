import React from 'react';
import logo from '../../assets/img/logo13.svg';
import thanks from '../../assets/img/thanks 1.svg';
import icon from '../../assets/img/icon1.svg';
import icon2 from '../../assets/img/icon2.svg';
import icon3 from '../../assets/img/icon3.svg';
import icon4 from '../../assets/img/icon4.svg';
import Section from '../Section';


const Mail3 = () => {
  return (
    <Section topMd={200} topLg={150} topXl={110}>
      <div className='container'>
        <div className='col-md-12'>
          <div className='py-4 rest-of-page'>
            <div className='container text-center'>
              <img src={logo} alt='Logo' className='imageStyle' />
            </div>
            </div>
            <div className='text-center mt-5'><h1> Thanks!</h1>
            <img src={thanks} alt='Logo' className='imageStyle3' />
            </div>
            <div className='content'>
              <h4 className='mt-5'>Dear [Name],</h4>
              <p>
              Thank you for subscribing to our [Plan Name] plan! We're thrilled to welcome you to the Health Matrix family and empower you to take control of your health with exclusive benefits.</p>
              <p>Your [Plan Name] subscription includes:</p>
              <ul>
                <li>
                [Discounted rates] discount on OPD services (consultations, diagnostics, etc.) </li>
                <li>Cashback rewards on your healthcare expenses</li>
              </ul>
              <p>For more details read T&C</p>
              <h4>Get Started & Maximize Your Savings:</h4>
              <ul>
              
              <img src={icon} alt='icon' className='icons' /><span>Get access to all radiology and pathology test, and enjoy the heavy discounted rates.</span> <br />
              <img src={icon2} alt='icon' className='icons' /><span>We made expensive medicines, affordable and accessible. Just think of the cure not the cost.</span><br />
              <img src={icon3} alt='icon' className='icons' /><span>Get free tele consultation from the experienced and reputed doctors. Never worry about the fees.</span> <br />
              <img src={icon4} alt='icon' className='icons' /><span>Get interest free medical loan of up to 1 lac for 3 months instantly.</span>
              </ul>
           
              <h4>Exclusive Member Resources:</h4>
              <p>You'll soon receive a separate email with your login credentials to access our secure Customer Relationship Management (CRM) portal. Through the CRM, you can:</p>
              <ul>
              <li>View your plan details and benefits</li>
              <li>Track your appointments and cashback history</li>
              <li>Manage your account information</li>
              </ul>
              <p>We're confident your subscription will enhance your healthcare experience. If you have any questions, please don't hesitate to contact us at [Phone Number] or reply to this email.</p>
        
              <p className='mt-5'>Thank you for choosing <br /> HealthMatrix!
              </p>
            </div>
            <style>
              {`
                .rest-of-page {
                  background: var(--hmisfront-easeclaim-com-linear-mariner-san-juan, linear-gradient(107deg, #8AC78B 0%, #19ADB4 100%));
                  padding: 20px;
                  margin-top: 75px;
                }
                .imageStyle {
                  width: 180px;
                  height: 50px;
                  flex-shrink: 0;
                }
                .content {
                  padding: 0 20px;
                  text-align: center;
                }
                .content h4, .content p, .content ul {
                  text-align: left;
                }
                .content ul {
                  list-style-position: inside;
                }
                .imageStyle3 {
                    width: 350px;
                    height: 300.96px;
                    flex-shrink: 0;
                    margin-top: -100px;
                  }
                 
                
                span{
                    font-size: 16px;
                    font-weight: 500;
                    color: #888;
                    margin-left: 10px;
                }
              `}
            </style>
          </div>
        </div>
      
    </Section>
  );
};

export default Mail3;
